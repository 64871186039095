<template>
  <div class="wrap">
    <!-- 忘记密码 -->
    <div class="container" v-if="state == 'forgetPassword'" key="forgetPassword">
      <h1>找回密码</h1>
      <div class="content">
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" class="demo-ruleForm">
          <el-form-item prop="phone">
            <el-input placeholder="手机号码" v-model.number="ruleForm2.phone" :maxlength="11" @keyup.enter.native="verifyMobile('ruleForm2')"></el-input>
          </el-form-item>
          <el-form-item prop="picCode">
            <div class="form-item">
              <el-input placeholder="图形验证码" v-model="ruleForm2.picCode" @keyup.enter.native="verifyMobile('ruleForm2')"></el-input>
              <div class="figure_code" @click="getImgCode()"><img v-bind:src="'data:image/png;base64,' + imagebase" /></div>
            </div>
          </el-form-item>
          <el-form-item prop="msgCode">
            <div class="form-item">
              <el-input placeholder="验证码" v-model="ruleForm2.msgCode" @keyup.enter.native="verifyMobile('ruleForm2')"></el-input>
              <div class="send_code" @click="getCode('ruleForm2')">{{ getCodeShow ? '发送验证码' : count + 's' }}</div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" @click="verifyMobile('ruleForm2')">下一步</el-button>
          </el-form-item>

          <p class="foot2">
            已有账号，<router-link to="/home/Login?state=login"><span>马上登录</span></router-link>
          </p>
        </el-form>
      </div>
    </div>
    <!-- 重置密码 -->
    <div class="container" v-else-if="state == 'resetPassword'" key="resetPassword">
      <h1>重置密码</h1>
      <div class="content">
        <el-form :model="ruleForm3" :rules="rules3" ref="ruleForm3" class="demo-ruleForm">
          <el-form-item prop="password1">
            <el-input placeholder="新密码" v-model="ruleForm3.password1" show-password @keyup.enter.native="resetPassword('ruleForm3')"></el-input>
          </el-form-item>
          <el-form-item prop="password2">
            <el-input placeholder="确认密码" v-model="ruleForm3.password2" show-password @keyup.enter.native="resetPassword('ruleForm3')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" @click="resetPassword('ruleForm3')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 登录 -->
    <div class="container" v-else key="login">
      <h1>登录</h1>
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="nickname">
            <el-input placeholder="请输入登录名" prefix-icon="el-icon-s-custom" v-model="ruleForm.nickname" @keyup.enter.native="login('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="请输入密码" prefix-icon="el-icon-key" v-model="ruleForm.password" show-password @keyup.enter.native="login('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item prop="picCode">
            <div class="form-item">
              <el-input placeholder="图形验证码" v-model="ruleForm.picCode" @keyup.enter.native="login('ruleForm')"></el-input>
              <div class="figure_code" @click="getImgCode()"><img v-bind:src="'data:image/png;base64,' + imagebase" /></div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="button" @click="login('ruleForm')">登录</el-button>
          </el-form-item>

          <div class="foot">
            <router-link to="/home/Login?state=forgetPassword">
              <span class="foot-item">忘记密码</span>
            </router-link>
            <span style="margin:0 10px">|</span>
            <router-link to="/home/UserRegister">
              <span class="foot-item">注册</span>
            </router-link>
          </div>
        </el-form>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" :show-close="false" width="380px" top="34vh">
      <div class="success-box">
        <i class="el-icon-success"></i>
        <p class="success-title">密码重置成功</p>
        <el-button plain class="success-btn" @click="toLogin()">马上登录</el-button>
      </div>
    </el-dialog>
    <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event"/>
  </div>
</template>

<script>
  import {getCode, login, selectCountByTel, verifyCode,updatePwdByTel,selectAccountTypeByLoginName,getCartCount, getPermission} from '../api'
  import {getWXImgCode} from '../api'
  import CFCATool from '@/components/CFCATool';

function getImgCode(v) {
  getWXImgCode().then(res => {
    console.log(res);
    v.imagebase = res.data.img;
    if (v.imgUuid == res.data.uuid) {
      v.offcache();
    }
    v.imgUuid = res.data.uuid;
  });
  }
  export default {
  components: {
    CFCATool
  },
  mounted: function () {
      //获取图形验证码
    getImgCode(this);
  },
  created() {
    this.state = this.$route.query.state ? this.$route.query.state : 'login';
  },
  // 生命周期 - 卸载前
  beforeDestroy () {
    localStorage.removeItem('certInfo');
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm3.password2 !== '') {
          this.$refs.ruleForm3.validateField('password2');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm3.password1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validatepassword1 = (rule, value, callback) => {
      var ok = true;
      var pwd = true;
      const FloatRegex = /^[A-Za-z0-9~@#$*]*$/; //只能包含（~@#$*）
      const FloatRegex2 = /^.{6,8}$/; //6-8
      const FloatRegex3 = /^[^\u4e00-\u9fa5]+$/; //不能中文
      const FloatRegex4 = /[A-Z]+/; //必须包含大写字母
      const FloatRegex5 = /[a-z]+/; //必须包含小写字母
      const FloatRegex6 = /[0-9]+/; //必须包含小写字母

      if (!FloatRegex.test(value)) {
        ok = false;
        callback(new Error('不能出现特殊字符,只能包含（~@#$*）'));
      }
      if (!FloatRegex2.test(value)) {
        ok = false;
        callback(new Error('长度6-8'));
      }

      if (!FloatRegex3.test(value)) {
        ok = false;
        callback(new Error('不能出现中文'));
      }
      if (!FloatRegex4.test(value)) {
        ok = false;
        pwd = false;
      }
      if (!FloatRegex5.test(value)) {
        ok = false;
        pwd = false;
        // callback(new Error("必须包含小写字母"))
      }
      if (!FloatRegex6.test(value)) {
        ok = false;
        pwd = false;
        // callback(new Error("必须包含数字"))
      }
      if (!pwd) {
        callback(new Error('必须包含大、小写字母和数字'));
      }

      if (ok) {
        callback();
      }
    };

    return {
      resubmit: true,
      imagebase: '12345',
      imgUuid: '',
      phoneCodeUUID: '',
      state: 'login', // login: 登录; forgetPassword: 忘记密码; resetPassword: 重置密码;
      getCodeShow: true,
      count: 60,
      timer: null,

      ruleForm: {
        nickname: '',
        password: '',
        picCode: ''
      },
      rules: {
        nickname: [{required: true, message: '请输入登录名', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        picCode: [{required: true, message: '请输入图形验证码', trigger: 'blur'}]
      },
      ruleForm2: {
        phone: '',
        picCode: '',
        msgCode: ''
      },
      rules2: {
        phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
        picCode: [{required: true, message: '请输入图形验证码', trigger: 'blur'}],
        msgCode: [{required: true, message: '请输入验证码', trigger: 'blur'}]
      },
      ruleForm3: {
        password1: '',
        password2: ''
      },
      rules3: {
        password1: [
          {required: true, validator: validatePass1, trigger: 'blur'},
          {required: false, validator: validatepassword1, trigger: 'blur'}
        ],
        password2: [
          {required: true, validator: validatePass2, trigger: 'blur'},
          {required: false, validator: validatepassword1, trigger: 'blur'}
        ]
      },

      dialogVisible: false,
      certInfo: ''
    };
  },
  methods: {
    offcache() {
      this.$notify.warning({
        title: '提示',
        message: '请前往浏览器internet选项>设置>internet临时文件：勾选:每次访问网页时（E）'
      });
    },
    getImgCode() {
      getImgCode(this);
    },
    // 登录
    login(formName) {
      var _this = this;
      var isLogin = false;

      if(this.$refs.CFCARef.checkIsIE()){
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo()
        if (!this.certInfo) return ;
        //  this.$message.info('未检测到证书信息')
      }

      localStorage.setItem('certInfo', this.certInfo);

      _this.$refs[formName].validate(valid => {
        if (valid) {
          if (!_this.resubmit) {
            return false;
          }
          _this.resubmit = false;
          console.log(_this.ruleForm.nickname);
          console.log(_this.ruleForm.password);
          login({
            loginName: _this.ruleForm.nickname,
            loginPwd: _this.ruleForm.password,
            code: _this.ruleForm.picCode,
            uuid: _this.imgUuid
          })
            .then(res => {
              if (res.errno == 0) {
                let {errmsg} = res;
                //   _this.$message.success('登录成功');
                _this.setInof('logined', _this.ruleForm.nickname);
                _this.setInof('loginSession', res.data);
                getPermission();

                localStorage.setItem('loginName', _this.ruleForm.nickname);
                _this.ruleForm = {
                  nickname: '',
                  password: ''
                };
                isLogin = true;

                var loginName = localStorage.getItem('loginName');

                this.$notify.success({
                  title: '成功',
                  message: errmsg
                });
                selectAccountTypeByLoginName({
                  loginName: loginName
                }).then(res => {
                  if (res.errno == 0) {
                    localStorage.setItem('accountType', res.data.accountType);
                    localStorage.setItem('userId', res.data.id);
                    localStorage.setItem('parentId', res.data.parentId);
                    localStorage.setItem('tel', res.data.tel);
                    localStorage.setItem('enterpriseId', res.data.enterpriseId);
                    localStorage.setItem('users', res.data.users);
                    localStorage.setItem('avatar', res.data.avatar);
                    localStorage.setItem('userInfo', JSON.stringify(res.data || {}));

                    if (errmsg === '登录成功,请尽快完善企业信息') {
                      this.$router.push({
                        path: '/User/AttestInfo?t=' + Date.now()
                      });
                    } else {
                      _this.$router.push({
                        path: '/home/HomePage?t=' + Date.now()
                      });
                      // window.location.reload();
                    }
                  }
                  _this.resubmit = true;
                });
              } else {
                getImgCode(_this);
                _this.resubmit = true;
              }
            })
            .catch(res => {
              _this.resubmit = true;
            });
        }
      });
    },
    // 忘记密码-获取验证码
    getCode(formName) {
      var _this = this;
      var valida_all = true;
      _this.$refs[formName].validateField(['phone', 'picCode'], valid => {
        if (valid) {
          valida_all = valida_all && false;
        } else {
          valida_all = valida_all && true;
        }
      });
      if (valida_all && _this.getCodeShow) {
        //
        selectCountByTel({tel: _this.ruleForm2.phone, scene: '3'}).then(res => {
          if (res.errno == 0) {
            getCode({
              phoneNum: _this.ruleForm2.phone,
              scene: '8'
            }).then(res => {
              console.log('验证码：' + res.data.code);
              console.log('uuid:' + res.data.uuid);
              _this.phoneCodeUUID = res.data.uuid;
            });
            const TIME_COUNT = 60;
            if (!_this.timer) {
              _this.count = TIME_COUNT;
              _this.getCodeShow = false;
              _this.timer = setInterval(() => {
                if (_this.count > 0 && _this.count <= TIME_COUNT) {
                  _this.count--;
                } else {
                  _this.getCodeShow = true;
                  clearInterval(this.timer);
                  _this.timer = null;
                }
              }, 1000);
            }
          }
        });
        //

        //获取验证码接口
      }
    },
    // 忘记密码-下一步 验证手机号码
    verifyMobile(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('下一步 重置密码');
          //校验验证码
          verifyCode({code: _this.ruleForm2.picCode, uuid: _this.imgUuid}).then(response => {
            if (response.errno == 0) {
              verifyCode({code: _this.ruleForm2.msgCode, uuid: _this.phoneCodeUUID}).then(response => {
                if (response.errno == 0) {
                  _this.$router.push({
                    path: '/home/Login?state=resetPassword'
                  });
                } else {
                  alert('短信验证输入有误');
                }
              });
            } else {
              alert('图形验证输入有误');
            }
          });
        }
      });
    },
    //
    resetPassword(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          //重置密码
          updatePwdByTel({
            pwd: _this.ruleForm3.password1,
            tel: _this.ruleForm2.phone,
            phoneCode: _this.ruleForm2.msgCode,
            phoneCodeUUID: _this.phoneCodeUUID,
            ImgCode: _this.ruleForm2.picCode,
            imgCodeUUID: _this.imgUuid
          }).then(response => {
            if (response.errno == 0) {
              _this.ruleForm2 = {
                phone: '',
                picCode: '',
                msgCode: ''
              };
              _this.ruleForm3 = {
                password1: '',
                password2: ''
              };
              _this.dialogVisible = true;
            } else {
              // alert("页面已经过期")
            }
          });
        }
      });
    },
    // 去登录
    toLogin() {
      this.dialogVisible = false;
      this.$router.push({
        path: '/home/Login?state=login'
      });
    },
    setCookie(name, value, seconds) {
      seconds = seconds || 0; //seconds有值就直接赋值，没有为0
      var expires = '';
      if (seconds != 0) {
        //设置cookie生存时间
        var date = new Date();
        date.setTime(date.getTime() + seconds * 1000);
        expires = '; expires=' + date.toGMTString();
      }
      document.cookie = name + '=' + escape(value) + expires + '; path=/'; //转码并赋值
    },
    setInof(key, value) {
      localStorage.setItem(key, value);
      this.setCookie(key, value); //存储localStorage的同时，也存储一个cookie来监听
    }
  },
  watch: {
    $route: function(newV, oldV) {
      this.state = this.$route.query.state ? this.$route.query.state : 'login';
    }
  }
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
h1 {
  margin: 0;
  padding: 0;
}
.wrap {
  width: 100%;
  height: 550px;
  background: #2ebfff;
  overflow: hidden;
}
.container {
  width: 340px;
  margin: 100px auto;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  h1 {
    position: relative;
    font-size: 18px;

    &::after {
      position: absolute;
      left: -20px;
      top: 2px;
      width: 4px;
      height: 19px;
      content: '';
      background: #409eff;
    }
  }

  .content {
    margin-top: 14px;

    .btn {
      // border-radius: 0;
      border: 0;
      width: 100%;
      background-image: linear-gradient(to right, #acb2ca, #33a2c5);
    }

    .foot {
      font-size: 14px;

      .foot-item {
        cursor: pointer;
        color: #333;
      }
    }

    .form-item {
      display: flex;

      .figure_code {
        margin-left: 10px;
        width: 180px;
        height: 40px;
        text-align: center;
        color: #fff;
        margin-top: 1px;
      }
      .send_code {
        margin-left: 10px;
        background: darkorange;
        width: 180px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .foot2 {
      font-size: 14px;
      color: #333;

      span {
        cursor: pointer;
        color: #33a2c5;
      }
    }
  }
}

.success-box {
  text-align: center;

  .el-icon-success {
    display: block;
    font-size: 50px;
    color: forestgreen;
  }
  .success-title {
    font-size: 18px;
    margin-top: 14px;
  }
  .success-btn {
    display: block;
    margin: 30px auto;
    width: 150px;
  }
}

.button {
  // border-radius: 0;
  border: 0;
  width: 100%;
  background-image: linear-gradient(to right, #00a9ff, #0a7def);
}
</style>
